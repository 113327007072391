import { useContext, useEffect, useState } from 'react';
import axiosConn from '../../axiosConn';
import { AuthContext } from '../../context/AuthContext';
import { Tooltip } from '@mui/material';

import './cashoutJGKToken.css';
import DisplayPlayerJGK from '../../components/displayPlayerJGK/DisplayPlayerJGK';
import Stack from 'react-bootstrap/Stack';
import { CircularProgress } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import ToggleButton from 'react-bootstrap/ToggleButton';
import { ViewPortContext } from '../../context/ViewPortContext';
import { useGetCurrPlayer } from '../../hooks/useGetCurrPlayer';

export default function CashoutJGKToken({
  showJGKTutorial,
  SetShowJGKTutorial,
}) {
  // const reloadlyApiClientIdProd = process.env.RELOADLY_API_CLIENT_ID_PROD;
  // const reloadlyApiClientSecretProd =
  //   process.env.RELOADLY_API_CLIENT_SECRET_PROD;
  // const reloadlyApiAudienceProd = process.env.RELOADLY_API_AUDIENCE_PROD;

  const { player } = useContext(AuthContext);
  const [myFriends, SetMyFriends] = useState([]);
  const [cashoutGiftCard, setCashoutGiftCard] = useState(1);
  const [sendJGKPlayer, setSendJGKPlayer] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState('Choose a Player');
  const [selectedGiftCard, setSelectedGiftCard] = useState(
    'Choose a Type of Gift Card',
  );
  const [sendJGKToPlayerAmount, setSendJGKToPlayerAmount] = useState(0);
  const [cashoutGiftCardAmount, setCashoutGiftCardAmount] = useState(0);
  const [sendJGKToPlayerMemo, setSendJGKToPlayerMemo] = useState(
    'sharing JGK is caring.',
  );
  const [giftCards, setGiftCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currPlayer, setCurrPlayer] = useState({});
  const [message, setMessage] = useState('');
  const [error, SetError] = useState('');
  const [ip, setIP] = useState('');
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const [giftCardIsSelected, setGiftCardIsSelected] = useState(false);
  const [thisGiftCard, setThisGiftCard] = useState('');
  const [active, setActive] = useState('');
  const [reloadlyAccessToken, setReloadlyAccessToken] = useState(
    JSON.parse(localStorage.getItem('reloadlyAccessToken') || null),
  );
  const [reloadlyAccessTokenExpiration, setReloadlyAccessTokenExpiration] =
    useState(
      JSON.parse(localStorage.getItem('reloadlyAccessTokenExpiration') || null),
    );
  const [giftCardUSDAmount, setGiftCardUSDAmount] = useState(0);

  const { width } = useContext(ViewPortContext);

  useEffect(() => {
    // check if reloadly access token exist in local storage.
    let mounted = true;

    const reloadlyAccessToken = JSON.parse(
      localStorage.getItem('reloadlyAccessToken'),
    );
    // console.log('reloadly Access Token: ', reloadlyAccessToken);
    // console.log(new Date().getTime(), reloadlyAccessTokenExpiration);

    if (
      reloadlyAccessToken === null ||
      reloadlyAccessToken === typeof undefined ||
      //time now is > token expiration time.
      // check if expired, 24 hours in seconds.
      // just get a new token when 70% seconds used.
      new Date().getTime() > reloadlyAccessTokenExpiration * 0.7
    ) {
      // if expired or not exist, get one.
      // const getReloadlyAccessToken = async () => {
      //   try {
      //     const reloadlyAccessTokenObject = await axiosConn.post(
      //       // 'https://auth.reloadly.com/oauth/token',
      //       'https://auth.reloadly.com/oauth/token',
      //       {
      //         //client_id: 'XxdYidFrecCti0ilQNYX9HsrdnXrXibO',,
      //         client_id: 'BNx9ELmEwLHOYJAYqhIyiRQVo2YBHws0',
      //         // client_secret:
      //         //   '0QxisebAfW-q5vU7YGEgi2a2bVsB4J-lj7PWs1o2GtaZFLV1bHNWE3Yg9CjnwtC',
      //         client_secret:
      //           '7aXSreNpCk-053KB4ElcAlAAiAA1HG-HHisXWUg4fvI5cSJ7AzouhuINpbPxp4x',
      //         grant_type: 'client_credentials',
      //         // audience: 'https://giftcards-sandbox.reloadly.com',
      //         audience: 'https://giftcards.reloadly.com',
      //       },
      //       {
      //         headers: {
      //           'Content-Type': 'application/json',
      //           Accept: 'application/json',
      //         },
      //       },
      //     );
      const getReloadlyAccessToken = async () => {
        try {
          // console.log(
          //   process.env.REACT_APP_RELOADLY_CLIENT_ID,
          //   process.env.REACT_APP_RELOADLY_CLIENT_SECRET,
          //   process.env.REACT_APP_RELOADLY_AUDIENCE,
          // );
          const reloadlyAccessTokenObject = await axiosConn.post(
            'https://auth.reloadly.com/oauth/token',
            {
              client_id: process.env.REACT_APP_RELOADLY_CLIENT_ID,
              client_secret: process.env.REACT_APP_RELOADLY_CLIENT_SECRET,
              grant_type: 'client_credentials',
              audience: process.env.REACT_APP_RELOADLY_AUDIENCE,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          );
          // console.log(reloadlyAccessTokenObject.data);
          if (!mounted) return;

          // console.log(reloadlyAccessTokenObject.data);
          setReloadlyAccessToken(reloadlyAccessTokenObject.data);
          // setReloadlyAccessTokenExpiration(
          //   new Date().getTime() +
          //     reloadlyAccessTokenObject.data.expires_in * 1000,
          // );
          localStorage.setItem(
            'reloadlyAccessTokenExpiration',
            JSON.stringify(
              new Date().getTime() +
                reloadlyAccessTokenObject.data.expires_in * 1000,
            ),
          );

          // store in local storage.
          localStorage.setItem(
            'reloadlyAccessToken',
            JSON.stringify(reloadlyAccessTokenObject.data),
          );
        } catch (err) {
          console.error(err);
        }
      };
      getReloadlyAccessToken();
    }
    return () => {
      mounted = false;
    };
  }, [reloadlyAccessTokenExpiration]);

  useEffect(() => {
    const getData = async () => {
      await axiosConn.get('https://ipapi.co/json/').then((res) => {
        let data = res.data;
        // console.log(res.data);
        setIP(res.data.ip);
        setCountryName(res.data.country_name);
        setCountryCode(res.data.country_code);
      });
    };
    getData();
  }, []);

  useEffect(() => {
    // then get all giftcards call.
    let mounted = true;

    const getGiftCardProducts = async () => {
      if (
        reloadlyAccessToken === null ||
        reloadlyAccessToken === typeof undefined
      ) {
        // console.log('reloadlyAccessToken is null');
        return;
      }
      try {
        // console.log(countryCode);
        const giftCardList = await axiosConn.get(
          // 'https://giftcards-sandbox.reloadly.com/countries/US/products',
          // 'https://giftcards.reloadly.com/products/countries/US/products',
          `https://giftcards.reloadly.com/products?size=&page=&productName=&countryCode=${countryCode}&includeRange=true&includeFixed=true`,
          {
            headers: {
              Accept: 'application/com.reloadly.giftcards-v1+json',
              Authorization: `Bearer ${reloadlyAccessToken.access_token}`,
            },
          },
        );

        // setOnlineFriends(friends.filter((f) => onlinePlayers.includes(f._id)));
        // console.log(giftCardList.data.content);

        // .sort((a, b) => {
        //   return new Date(b.commentAt) - new Date(a.commentAt);
        // })
        // console.log(
        //   giftCardList.data.content
        //     .filter((product) => {
        //       return (
        //         product.productName.includes('Amazon US') ||
        //         product.productName.includes('Regal') ||
        //         product.productName.includes('Amazon US') ||
        //         product.productName.includes('Uber') ||
        //         product.productName.includes('Visa® Prepaid Card USD US') ||
        //         product.productName.includes(
        //           'Mastercard® Prepaid Card USD US',
        //         ) ||
        //         product.productName.includes('Dominos') ||
        //         product.productName.includes('1-800-Flowers.com') ||
        //         product.productName.includes('Ace Hardware') ||
        //         product.productName.includes('AMC Theatres') ||
        //         product.productName.includes('Apple Music 12 month US') ||
        //         product.productName.includes('Apple Music 3 month US') ||
        //         product.productName.includes('Bitcoin US') ||
        //         product.productName.includes('Columbia Sportswear') ||
        //         product.productName.includes('CVS/pharmacy') ||
        //         product.productName.includes('ebay') ||
        //         product.productName.includes('GameStop US') ||
        //         product.productName.includes('Lowes') ||
        //         product.productName.includes('Minecraft US') ||
        //         product.productName.includes(
        //           'Pandora Plus 6 Month Subscription',
        //         ) ||
        //         product.productName.includes(
        //           'Pandora Plus 12 Month Subscription',
        //         ) ||
        //         product.productName.includes('Spotify US') ||
        //         product.productName.includes('SiriusXM') ||
        //         product.productName.includes('1-800-PetSupplies')
        //       );
        //     })
        //     .sort((a, b) => {
        //       return b.discountPercentage - a.discountPercentage;
        //     }),
        // );
        if (!mounted) return;

        setGiftCards(
          giftCardList.data.content
            // .filter((product) => {
            //   return (
            //     product.productName.includes('Amazon US') ||
            //     product.productName.includes('Regal') ||
            //     product.productName.includes('Amazon US') ||
            //     product.productName.includes('Uber') ||
            //     product.productName.includes('Visa® Prepaid Card USD US') ||
            //     product.productName.includes(
            //       'Mastercard® Prepaid Card USD US',
            //     ) ||
            //     product.productName.includes('Dominos') ||
            //     product.productName.includes('1-800-Flowers.com') ||
            //     product.productName.includes('Ace Hardware') ||
            //     product.productName.includes('AMC Theatres') ||
            //     product.productName.includes('Apple Music 12 month US') ||
            //     product.productName.includes('Apple Music 3 month US') ||
            //     product.productName.includes('Bitcoin US') ||
            //     product.productName.includes('Columbia Sportswear') ||
            //     product.productName.includes('CVS/pharmacy') ||
            //     product.productName.includes('ebay') ||
            //     product.productName.includes('GameStop US') ||
            //     product.productName.includes('Lowes') ||
            //     product.productName.includes('Minecraft US') ||
            //     product.productName.includes(
            //       'Pandora Plus 6 Month Subscription',
            //     ) ||
            //     product.productName.includes(
            //       'Pandora Plus 12 Month Subscription',
            //     ) ||
            //     product.productName.includes('Spotify US') ||
            //     product.productName.includes('SiriusXM') ||
            //     product.productName.includes('1-800-PetSupplies')
            //   );
            // })
            .sort((a, b) => {
              return b.discountPercentage - a.discountPercentage;
            }),
        );
      } catch (err) {
        console.error(err);
      }
    };
    getGiftCardProducts();
    return () => {
      mounted = false;
    };
  }, [countryCode, reloadlyAccessToken, reloadlyAccessToken?.access_token]);

  const getCurrentPlayer = async () => {
    try {
      setIsLoading(true);
      const res1 = await axiosConn.get(
        `/players/?playerId=${player.data.player.playerId}`,
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      setCurrPlayer(res1.data.data.data[0]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { data, status } = useGetCurrPlayer(onSuccess, onError, player);
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status === 'success') {
      if (!mounted) return;
      setCurrPlayer(data);
    }
    return () => {
      mounted = false;
    };
  }, [status, data]);

  useEffect(() => {
    let mounted = true;
    //This grabs logged on player's friends.
    const getMyFriends = async () => {
      try {
        const friendList = await axiosConn.get(
          '/players/friends/' + player.data.player._id,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        if (!mounted) return;
        SetMyFriends(friendList.data);
        // console.log(friendList.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMyFriends();
    return () => {
      mounted = false;
    };
  }, [player.token, player.data.player._id]);

  const handleClickGiftCard = (event) => {
    // console.log(event);
    cashoutGiftCard === 0 ? setCashoutGiftCard(1) : setCashoutGiftCard(0);
    // console.log(cashoutGiftCard);
    if (cashoutGiftCard === 0) {
      setSelectedGiftCard('Choose a Type of Gift Card');
      setCashoutGiftCardAmount(0);
      setActive('');
      setGiftCardIsSelected(false);
      setGiftCardUSDAmount(0);
      setThisGiftCard('');
    }
  };

  const handleSendJGKPlayer = (event) => {
    // console.log(event);
    sendJGKPlayer === 0 ? setSendJGKPlayer(1) : setSendJGKPlayer(0);
    // console.log(sendJGKPlayer);
    if (sendJGKPlayer === 0) {
      setSelectedPlayer('Choose a Player');
      setSendJGKToPlayerAmount(0);
    }
  };

  const handleSelectedGiftCard = (event) => {
    // console.log(giftCards);
    const chosenGiftCard = giftCards.filter(
      (giftCard) => event.target.value === giftCard.productName,
    );
    // console.log(chosenGiftCard[0]);
    setGiftCardIsSelected(true);
    setThisGiftCard(chosenGiftCard[0]);
  };

  const handleCashoutGiftCardAmount = async (e) => {
    // console.log(
    //   e.key,
    //   e.type,
    //   cashoutGiftCardAmount,
    //   selectedGiftCard,
    //   e.target.value,
    // );
    //reset error backspacing to fix amount.
    if (e.key === undefined && error !== '') {
      // console.log(e.key, 'resetting Error', error);
      SetError('');
    }

    //3% JGK transaction fee.
    // 1 JGK = .01 USD current conversion
    // thisGiftCard.discountPercentage flowers 6%
    //maxRecipientDenomination: flowers 100.
    //senderFee flowers .5
    //rounding and max between cashoutGiftCardAmount & maxRecipientDenomination.

    //settting USD Amount
    if (thisGiftCard.denominationType === 'RANGE') {
      setGiftCardUSDAmount(
        // parseFloat(cashoutGiftCardAmount * 0.97 * 0.01).toFixed(2),
        parseFloat(cashoutGiftCardAmount * 0.01).toFixed(2),
      );
    } else if (thisGiftCard.denominationType === 'FIXED') {
      // console.log(cashoutGiftCardAmount, giftCardUSDAmount);
    }

    if (e.key === 'Enter' || e.type === 'click') {
      // console.log('handleCashoutGiftCardAmount ordering');
      // console.log(
      //   player.data.player._id,
      //   cashoutGiftCardAmount,
      //   thisGiftCard.productName,
      // );
      // console.log(
      //   giftCardUSDAmount,
      //   thisGiftCard,
      //   currPlayer.jgkTokens,
      // );
      const usdJGKConversionRate = 100; //1 USD === 100 JGK
      const jgkTransactionFee = 0.03; //3% transaction fee.

      if (
        thisGiftCard.denominationType === 'RANGE' &&
        (giftCardUSDAmount < thisGiftCard.minSenderDenomination ||
          giftCardUSDAmount > thisGiftCard.maxSenderDenomination ||
          giftCardUSDAmount +
            thisGiftCard?.senderFee * usdJGKConversionRate +
            cashoutGiftCardAmount * jgkTransactionFee >
            currPlayer?.jgkTokens)
      ) {
        // console.log(giftCardUSDAmount, cashoutGiftCardAmount, thisGiftCard);
        SetError(
          `${thisGiftCard.productName} min: $${thisGiftCard.minSenderDenomination} and max: $${thisGiftCard.maxSenderDenomination}. Please check available JGK, minimum and maximum, adjust Amount and try again.`,
        );
        return;
      } else if (
        thisGiftCard.denominationType === 'FIXED' &&
        giftCardUSDAmount +
          thisGiftCard?.senderFee * usdJGKConversionRate +
          cashoutGiftCardAmount * jgkTransactionFee >
          currPlayer?.jgkTokens
      ) {
        SetError(`Please check available JGK, adjust Amount and try again.`);
        return;
      }

      setIsLoading(true);
      try {
        // console.log(`${player.data.player.playerId}${new Date().getTime()}`);

        await axiosConn.post(
          `/jgkTokenTransactions/jgkCashoutToGiftCard`,
          {
            sender: player.data.player._id,
            amount: cashoutGiftCardAmount,
            giftCard: thisGiftCard.productName,
            productId: thisGiftCard.productId,
            countryCode: thisGiftCard.countryCode,
            quantity: 1,
            unitPrice: giftCardUSDAmount,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // .then(async (res) => {
        //   //check response for 200
        //   // console.log(res);
        //   // console.log(giftCardIsSelected);
        //   // console.log(giftCardUSDAmount);
        //   const reloadlyOrderObject = await axiosConn.post(
        //     // 'https://giftcards-sandbox.reloadly.com/orders',
        //     'https://giftcards.reloadly.com/orders',
        //     {
        //       productId: thisGiftCard.productId,
        //       countryCode: 'US',
        //       quantity: 1,
        //       unitPrice: giftCardUSDAmount,
        //       customIdentifier: `${
        //         player.data.player.playerId
        //       }${new Date().getTime()}`,
        //       senderName: player.data.player.playerId,
        //       recipientEmail: player.data.player.emailAddress,
        //       recipientPhoneDetails: {
        //         countryCode: 'US',
        //         phoneNumber: player.data.player.phoneNumber,
        //       },
        //     },
        //     {
        //       headers: {
        //         Accept: 'application/com.reloadly.giftcards-v1+json',
        //         Authorization: `Bearer ${reloadlyAccessToken.access_token}`,
        //       },
        //     },
        //   );
        // });
        setIsLoading(false);
        // console.log(reloadlyOrderObject);
        setMessage(
          `Success! Your ${thisGiftCard.productName} gift card has been ordered. Check your email!`,
        );

        window.location.reload();
      } catch (err) {
        console.log(err);
        SetError(err.response.data.message);
        setIsLoading(false);
        // console.log(err);
      }
    }
  };

  const handleDenominationButtonClick = (e) => {
    // console.log(
    //   'handleDenominationButtonClick',
    //   e.target.value,
    //   e.target.id,
    //   parseFloat(e.target.value) * 100,
    // );
    setActive(e.target.id);
    setGiftCardUSDAmount(parseFloat(e.target.value));

    setCashoutGiftCardAmount(parseFloat(e.target.value) * 100);

    //so we'll have the current jgkTokens for Ordering.
    //case a player sends JGK just before ordering.
    getCurrentPlayer();
    // console.log(currPlayer.jgkTokens);
  };

  const handleSendJGKToPlayerAmount = async (e) => {
    // console.log(e.target.value);
    if (e.key === 'Enter' || e.type === 'click') {
      // console.log(selectedPlayer, sendJGKToPlayerAmount, sendJGKToPlayerMemo);
      //send JGK.
      setIsLoading(true);

      const selectedPlayerId = myFriends.filter(
        (f) => selectedPlayer === f.playerId,
      );
      // console.log(
      //   selectedPlayerId[0]._id,
      //   player.data.player._id,
      //   sendJGKToPlayerAmount,
      //   sendJGKToPlayerMemo,
      // );
      try {
        await axiosConn.post(
          `/jgkTokenTransactions/`,
          {
            receiver: selectedPlayerId[0]._id,
            sender: player.data.player._id,
            amount: sendJGKToPlayerAmount,
            text: sendJGKToPlayerMemo,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // window.location.href = res2.data.session.url;
        setIsLoading(false);
        window.location.reload();
      } catch (err) {
        setIsLoading(false);

        console.log(err);
      }
    }
  };

  const handleHelpIconClick = (event) => {
    // console.log('handleHelpIconClick()');
    SetShowJGKTutorial(showJGKTutorial === true ? false : true);
  };
  // console.log(countryCode, countryName, ip);

  return (
    <div className="jgkTokenBottom">
      <div className="jgkTokenWrapper">
        {/* <span>Cashout JGK</span> */}
        <Stack gap={10}>
          <div>
            <DisplayPlayerJGK />
          </div>
          {!sendJGKPlayer && (
            <div className="cashoutGiftCard">
              <Form>
                {['checkbox'].map((type) => (
                  <div
                    key={`default-${type}`}
                    className="mb-3 cashoutGiftCardCheckBox"
                  >
                    <Form.Check
                      type={type}
                      id={`cashoutGiftCard`}
                      defaultChecked={cashoutGiftCard}
                      label={` JGK to Gift Card`}
                      onClick={() => {
                        handleClickGiftCard();
                      }}
                      disabled={sendJGKPlayer ? true : false}
                    />
                    <Tooltip
                      title={
                        showJGKTutorial === false
                          ? 'Click & Watch JGK To Gift Card Video'
                          : 'Click to Close Tutorial Video'
                      }
                      arrow
                    >
                      <HelpOutlineOutlinedIcon
                        fontSize="small"
                        sx={
                          showJGKTutorial === true
                            ? { color: '#450b7a' }
                            : { color: '#8a2be2' }
                        }
                        onClick={handleHelpIconClick}
                      />
                    </Tooltip>
                  </div>
                ))}
              </Form>
              {cashoutGiftCard === 1 ? (
                <>
                  {/* <div> */}
                  {/* <CashoutGiftCardOptions /> */}
                  <>
                    <div className="giftCardSelectOrderFee">
                      <select
                        className="giftCardSelect"
                        name="giftcards"
                        id="giftcards"
                        onChange={(e) => {
                          setSelectedGiftCard(e.target.value);
                          // console.log(e.target.value);
                          handleSelectedGiftCard(e);
                          setActive('');
                          setGiftCardUSDAmount(0);
                          setCashoutGiftCardAmount(0);
                          SetError('');
                        }}
                      >
                        <option value={selectedGiftCard} selected>
                          {selectedGiftCard}
                        </option>
                        {/* <option value="Amazon">Amazon</option>
                        <option value="Walmart">Walmart</option> */}
                        {giftCards.map((giftCard, index) => (
                          <option key={index} value={giftCard.productName}>
                            {giftCard.productName}
                          </option>
                        ))}
                      </select>
                      <button
                        className={
                          selectedGiftCard === 'Choose a Type of Gift Card' ||
                          cashoutGiftCardAmount <= 0
                            ? 'sendJGKSubmitButtonDisabled'
                            : 'sendJGKSubmitButton'
                        }
                        value="Order"
                        onClick={handleCashoutGiftCardAmount}
                        disabled={
                          selectedGiftCard === 'Choose a Type of Gift Card' ||
                          cashoutGiftCardAmount <= 0
                            ? true
                            : false
                        }
                      >
                        {isLoading ? (
                          <CircularProgress color="inherit" size="20px" />
                        ) : (
                          'Order'
                        )}
                      </button>
                      {/* <span className="jgkFeeSpan">
                        {!mobileView
                          ? '3% JGK Fee'
                          : width < 300
                          ? '3%'
                          : '3% JGK'}
                      </span> */}
                    </div>
                    <div className="giftCardSelectAnAmount">
                      {thisGiftCard && (
                        <label for="giftCardAmount">
                          {!mobileView
                            ? thisGiftCard.denominationType === 'RANGE'
                              ? 'Enter JGK Value'
                              : 'Select an Amount'
                            : width < 300
                            ? '$'
                            : 'Amount $'}
                        </label>
                      )}
                      {/* {thisGiftCard && thisGiftCard.denominationType} */}
                      {thisGiftCard?.denominationType === 'RANGE' ? (
                        <>
                          <input
                            className="giftCardAmountInput"
                            type="number"
                            id="giftCardAmount"
                            name="giftCardAmount"
                            value={cashoutGiftCardAmount}
                            onChange={(e) => {
                              setCashoutGiftCardAmount(e.target.value);
                              // console.log(e.target.value);
                            }}
                            onInput={handleCashoutGiftCardAmount}
                            onKeyUp={handleCashoutGiftCardAmount}
                          />
                          {giftCardUSDAmount > 0 && (
                            <span>
                              {'  '} = ${giftCardUSDAmount}{' '}
                              {width < 300
                                ? thisGiftCard.productName.slice(0, 12)
                                : thisGiftCard.productName}
                            </span>
                          )}
                        </>
                      ) : thisGiftCard?.denominationType === 'FIXED' ? (
                        <>
                          {thisGiftCard.fixedRecipientDenominations.map(
                            (d, index) => {
                              return (
                                <button
                                  type="button"
                                  className={
                                    parseFloat(active) === d
                                      ? 'selectedAmountButtonActive'
                                      : 'unSelectedAmountButton'
                                  }
                                  onClick={(e) => {
                                    handleDenominationButtonClick(e);
                                  }}
                                  id={d}
                                  value={d}
                                  key={index}
                                  disabled={
                                    currPlayer.jgkTokens / 100 < d
                                      ? 'disabled'
                                      : null
                                  }
                                >
                                  {d}
                                </button>
                              );
                            },
                          )}
                        </>
                      ) : null}
                    </div>
                  </>
                  {/* </div> */}
                </>
              ) : null}
            </div>
          )}
          {!cashoutGiftCard && (
            <div className="sendJGKToPlayer">
              <Form>
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      type={type}
                      defaultChecked={sendJGKPlayer}
                      id={`sendJGKPlayer`}
                      label={` Send JGK to Your Friends`}
                      onClick={handleSendJGKPlayer}
                      disabled={cashoutGiftCard ? true : false}
                    />
                  </div>
                ))}
              </Form>
              {sendJGKPlayer === 1 ? (
                <>
                  <div>
                    <>
                      <div className="sendJGKToPlayerSelect">
                        <div>
                          <select
                            name="sendJGKToPlayer"
                            id="sendJGKToPlayer"
                            onChange={(e) => {
                              setSelectedPlayer(e.target.value);
                              // console.log(e.target.value);
                            }}
                          >
                            <option value={selectedPlayer} selected>
                              {selectedPlayer}
                            </option>

                            {myFriends.map((friend) => (
                              <option
                                key={friend._id}
                                value={`${friend.playerId}`}
                              >
                                {friend.playerId}
                              </option>
                            ))}
                          </select>
                          <button
                            className={
                              selectedPlayer === 'Choose a Player' ||
                              sendJGKToPlayerAmount <= 0
                                ? 'sendJGKSubmitButtonDisabled'
                                : 'sendJGKSubmitButton'
                            }
                            value="Send"
                            onClick={handleSendJGKToPlayerAmount}
                            disabled={
                              selectedPlayer === 'Choose a Player' ||
                              sendJGKToPlayerAmount <= 0
                                ? true
                                : false
                            }
                          >
                            {isLoading ? (
                              <CircularProgress color="inherit" size="20px" />
                            ) : (
                              'Send'
                            )}
                          </button>
                          <span className="jgkFeeSpan">3% JGK fee</span>
                        </div>
                        <div className="sendJGKToPlayerAmountInputs">
                          <label for="sendJGKToPlayerAmount">Amount:</label>
                          <input
                            type="number"
                            value={sendJGKToPlayerAmount}
                            id="sendJGKToPlayerAmount"
                            onChange={(e) =>
                              setSendJGKToPlayerAmount(e.target.value)
                            }
                            onKeyUp={handleSendJGKToPlayerAmount}
                            name="sendJGKToPlayerAmount"
                          ></input>
                        </div>
                        <div className="sendJGKToPlayerAmountInputs">
                          <label for="sendJGKToPlayerAmountMemo">Memo:</label>
                          <input
                            type="text"
                            value={sendJGKToPlayerMemo}
                            id="sendJGKToPlayerAmountMemo"
                            onChange={(e) =>
                              setSendJGKToPlayerMemo(e.target.value)
                            }
                            onKeyUp={handleSendJGKToPlayerAmount}
                            name="sendJGKToPlayerAmountMemo"
                            maxLength={512}
                          ></input>
                        </div>
                      </div>
                    </>
                  </div>
                </>
              ) : null}
            </div>
          )}
          {giftCardIsSelected && cashoutGiftCard ? (
            <div className="giftCardIsSelectedDetails">
              <Card
                style={{
                  width: '18rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Card.Img
                  style={{
                    padding: '5px',
                  }}
                  variant="top"
                  src={thisGiftCard ? thisGiftCard.logoUrls[0] : null}
                />
                <Card.Title
                  style={{
                    padding: '5px',
                    width: `62%`,
                  }}
                >
                  {thisGiftCard.productName}
                </Card.Title>
                <Card.Text
                  style={{
                    padding: '5px',
                    width: `80%`,
                    'word-wrap': 'break-word',
                  }}
                >
                  {thisGiftCard ? thisGiftCard.redeemInstruction.concise : null}
                </Card.Text>
                {error && (
                  <Card.Text
                    style={
                      error
                        ? {
                            padding: '5px',
                            width: `80%`,
                            color: '#b94a48',
                            'word-wrap': 'break-word',
                          }
                        : null
                    }
                  >
                    {thisGiftCard && error ? error : null}
                  </Card.Text>
                )}
                {message && (
                  <Card.Text
                    style={
                      message
                        ? {
                            padding: '5px',
                            width: `80%`,
                            color: '#42b72a',
                            'word-wrap': 'break-word',
                          }
                        : null
                    }
                  >
                    {thisGiftCard && error ? error : null}
                  </Card.Text>
                )}
              </Card>
            </div>
          ) : null}
          {message && <div className="success_text">{message}</div>}
          {/* {error && <div className="error_textCashoutJGKToken">{error}</div>} */}
        </Stack>
      </div>
    </div>
  );
}
