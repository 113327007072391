import './profile.css';
import Topbar from '../../components/topbar/Topbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Feed from '../../components/feed/Feed';
import Rightbar from '../../components/rightbar/Rightbar';
import { useEffect, useRef, useState } from 'react';
import axiosConn from '../../axiosConn';
import LoadingBar from 'react-top-loading-bar';
import { handleProfileAndCoverUpload } from '../../utils/handleProfileAndCoverUpload';
import { useParams } from 'react-router';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import EasyEdit from 'react-easy-edit';
import { CircularProgress } from '@material-ui/core';
import { handleYouTube } from '../../utils/youtubeUtil';
import MediaCover from '../../components/mediaCover/MediaCover';
import ModalImage from 'react-modal-image';

export default function Profile() {
  //const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const { player, updatePlayer } = useContext(AuthContext);
  const imageUploader = useRef(null);
  const coverImageUploader = useRef(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [player1, setPlayer1] = useState({});
  const playerId = useParams().playerId;
  const [isCloud, setIsCloud] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const textDefault = '-';
  const [progress, setProgress] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [isTube, setIsTube] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(player1.profilePhoto);
  // console.log(player1);
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    setProfilePhoto(player1.profilePhoto);
  }, [player1.profilePhoto]);

  const handleClick = (tag) => (event) => {
    if (tag === 'cover') coverImageUploader.current.click();
    else imageUploader.current.click();
  };

  const handleSave = (tag) => async (text) => {
    const currentPlayer = JSON.parse(localStorage.getItem('player'));

    // Check if tag is valid
    if (['desc', 'city', 'from'].includes(tag)) {
      // Update the player data
      currentPlayer.data.player[tag] = text !== '' ? text : textDefault;

      try {
        // Make the API call
        await axiosConn.patch(
          '/players/updateMe',
          { [tag]: currentPlayer.data.player[tag] },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );

        // Update the local storage
        localStorage.setItem('player', JSON.stringify(currentPlayer));
        setPlayer1(currentPlayer.data.player);
      } catch (err) {
        // Handle the error
        console.error(err);
      }
    }
  };

  const handleCancel = (tag) => (text) => {
    // console.log(text, tag);
  };

  //console.log(playerId);
  useEffect(() => {
    let mounted = true;
    const fetchPlayer = async () => {
      setIsLoading(true);

      //console.log('fetchPlayer', playerId);
      const res = await axiosConn.get(`/players?playerId=${playerId}`, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      //console.log('Profile: res: ', res);
      if (!mounted) {
        return;
      }
      setPlayer1(res.data.data.data[0]);
      // updatePlayer(res.data.data.data[0]);
      // console.log(res.data.data.data[0]);
      // localStorage.setItem('player', JSON.stringify(res.data.data.data[0]));

      setIsLoading(false);
    };
    fetchPlayer();
    return () => {
      mounted = false;
    };
  }, [playerId, player.token]);
  // console.log(player1);
  // console.log(player1.coverPicture);

  useEffect(() => {
    if (player1.coverPhoto) {
      handleYouTube(player1.coverPhoto, setIsVideo, setIsTube);
    }
  }, [player1.coverPhoto]);
  // console.log(player1.playerId, player.data.player.playerId);
  // console.log(player1?.playerId);
  return (
    <>
      <Topbar visitedPlayer={player1} />
      <div className="profile">
        <LoadingBar
          color="limegreen"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Sidebar />
        <div className="profileRight">
          <div className="profileRightTop">
            <div className="profileCover">
              <div
                className="CoverImgChange"
                onClick={() => coverImageUploader.current.click()}
              >
                <input
                  disabled={
                    isLoading ||
                    player1.playerId !== player.data.player.playerId
                      ? true
                      : undefined
                  }
                  type="file"
                  id="file"
                  accept="image/*,video/*"
                  onChange={handleProfileAndCoverUpload({
                    parent: 'player',
                    tag: 'cover',
                    setIsFetching,
                    setProgress,
                    setUploadPercentage,
                    setUpdateTrigger,
                    setPlayer1,
                    player,
                  })}
                  ref={coverImageUploader}
                  style={{
                    display: 'none',
                  }}
                />
                <MediaCover
                  isVideo={isVideo}
                  isTube={isTube}
                  mediaSource={player1.coverPhoto}
                  handleVideoLoad={handleVideoLoad}
                  parent="playerProfile"
                  player1={player1}
                />
              </div>

              <div className="profileImgChange">
                <input
                  disabled={player1.playerId !== player.data.player.playerId}
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={handleProfileAndCoverUpload({
                    parent: 'player',
                    tag: 'profile',
                    setIsFetching,
                    setProgress,
                    setUploadPercentage,
                    setUpdateTrigger,
                    setPlayer1,
                    player,
                    playerId,
                  })}
                  ref={imageUploader}
                  style={{
                    display: 'none',
                  }}
                />
                {player1.playerId === player.data.player.playerId ? (
                  <img
                    src={
                      profilePhoto
                        ? profilePhoto
                        : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                    }
                    alt=""
                    className="profilePlayerImg"
                    title={`${player1.playerId}, click to change your profile photo`}
                    onClick={handleClick('profile')}
                  />
                ) : (
                  <ModalImage
                    hideDownload={true}
                    small={
                      player1.profilePhoto
                        ? player1.profilePhoto
                        : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                    }
                    large={
                      player1.profilePhoto
                        ? player1.profilePhoto
                        : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                    }
                    className="profilePlayerImg"
                    src={
                      player1.profilePhoto
                        ? player1.profilePhoto
                        : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                    }
                    alt=""
                  />
                )}
              </div>
              <div className="profileChangeProgress">
                {isFetching ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : null}
              </div>
            </div>
            <div className="profileInfo">
              <h4 className="profileInfoName">{player1.playerId}</h4>
              <EasyEdit
                value={player1.desc}
                type="text"
                onSave={handleSave('desc')}
                onCancel={handleCancel('desc')}
                saveOnBlur
                attributes={{ name: 'awesome-input', id: 1 }}
                className="profileInfoDesc"
                allowEdit={player1.playerId === player.data.player.playerId}
              />
            </div>
          </div>
          <div className="profileRightBottom">
            <Feed playerId={playerId} />
            <Rightbar player={player} player1={player1} />
          </div>
        </div>
      </div>
    </>
  );
}
