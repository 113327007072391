import { useContext, memo, useEffect, useState } from 'react';
import Topbar from '../../components/topbar/Topbar';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import Sidebar from '../../components/sidebar/Sidebar';
import Rightbar from '../../components/rightbar/Rightbar';
import { useMediaQuery } from 'react-responsive';
// import { ViewPortContext } from '../../context/ViewPortContext';
import arePropsEqual from '../../arePropsEqual';
import { useParams } from 'react-router-dom';
import Meme from '../../components/meme/Meme';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';

import './memepage.css';

export default memo(function MemePage() {
  const { player } = useContext(AuthContext);
  const { memeId } = useParams(); //grab memeId
  const [meme, setMeme] = useState(null);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  // const { width } = useContext(ViewPortContext);
  // console.log(width);
  // console.log(memeId, 'memeId');

  useEffect(() => {
    const getMeme = async () => {
      const res = await axiosConn.get('/memes/' + memeId, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      // console.log(res.data.data.data);
      setMeme(res.data.data.data);
    };
    getMeme();
  }, [player.token, memeId]);

  return (
    <>
      <Topbar />
      {mobileView && <TopbarNotification />}
      <div className="homeContainer">
        <Sidebar />

        {memeId && meme && (
          <div className="feed">
            <div className="feedWrapper">
              <Meme meme={meme} />
            </div>
          </div>
        )}
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
}, arePropsEqual);
