import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import axiosConn from '../../axiosConn';
import Topbar from '../../components/topbar/Topbar';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import Sidebar from '../../components/sidebar/Sidebar';
import Feed from '../../components/feed/Feed';
import Rightbar from '../../components/rightbar/Rightbar';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../context/AuthContext';
import './feedback.css';

const FeedbackForm = () => {
  const { player } = useContext(AuthContext);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('general feedback');
  const [priority, setPriority] = useState('medium');
  const [titlePlaceholder, setTitlePlaceholder] = useState(
    'Enter feedback title',
  );
  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState(
    'Add feedback description',
  );
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  useEffect(() => {
    switch (type) {
      case 'bug':
        setTitlePlaceholder('Enter bug title');
        setDescriptionPlaceholder('Describe the bug in detail');
        break;
      case 'feature request':
        setTitlePlaceholder('Enter feature request title');
        setDescriptionPlaceholder('Describe the feature you are requesting');
        break;
      case 'general feedback':
        setTitlePlaceholder('Enter general feedback title');
        setDescriptionPlaceholder('Provide your general feedback');
        break;
      default:
        setTitlePlaceholder('Enter feedback title');
        setDescriptionPlaceholder('Add feedback description');
    }
  }, [type]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const feedbackData = { title, description, type, priority };
    try {
      const response = await axiosConn.post('/feedback', feedbackData, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      alert('Feedback submitted successfully!');
      // Clear the form after the alert is clicked
      setTitle('');
      setDescription('');
      setType('general feedback');
      setPriority('medium');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback.');
    }
  };

  return (
    <>
      <Topbar />
      {mobileView && <TopbarNotification />}
      <div className="homeContainer">
        <Sidebar />
        <div className="feed">
          <div className="uniqueFeedbackForm">
            <h1>Feedback Form</h1>
            <div className="feedbackMessage">
              Your Feedback means everything! Earn 100 JGK for Bugs & Features
              Submitted.
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Title:</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  placeholder={titlePlaceholder}
                  name="feedbackTitle"
                  className="feedback-input"
                />
              </div>
              <div>
                <label>Description:</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  placeholder={descriptionPlaceholder}
                  name="feedbackDescription"
                  className="feedback-input"
                />
              </div>
              <div>
                <label>Type:</label>
                <select
                  name="feedbackType"
                  className="feedback-input"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="bug">Bug</option>
                  <option value="feature request">Feature Request</option>
                  <option value="general feedback">General Feedback</option>
                </select>
              </div>
              <div>
                <label>Priority:</label>
                <select
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  name="feedbackPriority"
                  className="feedback-input"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                  <option value="critical">Critical</option>
                </select>
              </div>
              <button type="submit">Submit Feedback</button>
            </form>
          </div>
        </div>
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
};

export default FeedbackForm;
