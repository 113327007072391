import axios from 'axios';

export const getAirtimeAccessToken = async () => {
  const airtimeAccessToken = JSON.parse(
    localStorage.getItem('reloadlyAirtimeAccessToken'),
  );
  const airtimeAccessTokenExpiration = JSON.parse(
    localStorage.getItem('reloadlyAirtimeAccessTokenExpiration'),
  );

  if (
    !airtimeAccessToken ||
    new Date().getTime() > airtimeAccessTokenExpiration * 0.7
  ) {
    try {
      //   console.log(process.env.REACT_APP_RELOADLY_AIRTIME_AUDIENCE);
      const airtimeAccessTokenObject = await axios.post(
        'https://auth.reloadly.com/oauth/token',
        {
          client_id: process.env.REACT_APP_RELOADLY_CLIENT_ID,
          client_secret: process.env.REACT_APP_RELOADLY_CLIENT_SECRET,
          grant_type: 'client_credentials',
          audience: 'https://topups.reloadly.com/',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      const newAccessToken = airtimeAccessTokenObject.data;
      const newExpirationTime =
        new Date().getTime() + newAccessToken.expires_in * 1000;

      localStorage.setItem(
        'reloadlyAirtimeAccessToken',
        JSON.stringify(newAccessToken),
      );
      localStorage.setItem(
        'reloadlyAirtimeAccessTokenExpiration',
        JSON.stringify(newExpirationTime),
      );

      return newAccessToken;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  return airtimeAccessToken;
};
