import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import 'normalize.css';
import { ViewportProvider } from './context/ViewPortContext';
import { SocketContext, socket } from './context/socket';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import generateClassName from './utils/jssSetup';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SocketContext.Provider value={socket}>
        <ViewportProvider>
          <AuthContextProvider>
            <BrowserRouter>
              <StylesProvider generateClassName={generateClassName}>
                <App />
              </StylesProvider>
            </BrowserRouter>
          </AuthContextProvider>
        </ViewportProvider>
      </SocketContext.Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(
      function (registration) {
        // Registration was successful
        console.log(
          'ServiceWorker registration successful with scope 2: ',
          registration.scope,
        );
      },
      function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      },
    );
  });
}

// Listen for the service worker controlling the page to change
navigator.serviceWorker.addEventListener('controllerchange', () => {
  // The controlling service worker has changed, indicating a new service worker has taken control
  console.log('Controller changed. New content is available. Reloading...');
  window.location.reload();
});
