import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import axiosConn from '../../axiosConn';
import Topbar from '../../components/topbar/Topbar';
import useMobileView from '../../hooks/useMobileView';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import Sidebar from '../../components/sidebar/Sidebar';
import Rightbar from '../../components/rightbar/Rightbar';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { fetchIPData } from '../../utils/ipUtils';
import { getAirtimeAccessToken } from '../../utils/airtimeUtils';
import { AuthContext } from '../../context/AuthContext';
import { countryCodeToIso } from '../../utils/countryToIsoUtil';
import './airtime.css';

const Airtime = () => {
  const { player } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const mobileView = useMobileView();
  const [visible, setVisible] = useState(0);
  const [operatorData, setOperatorData] = useState(null);
  const [ip, setIP] = useState('');
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [accessToken, setAccessToken] = useState(null);
  const [showMin, setShowMin] = useState(0);
  const [showMax, setShowMax] = useState(0);
  const [jgkAmount, setJgkAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [airtimeTopupFee, setAirtimeTopupFee] = useState(0);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState('');
  const [isLocal, setIsLocal] = useState(false);

  // console.log(reloadlyAccessToken);
  const handlePhoneNumberChange = (value, country) => {
    console.log('Country:', country);
    setPhoneNumber(value);
    const phoneNumberCountryCode = country.dialCode;
    const isoCode = countryCodeToIso[phoneNumberCountryCode] || '';
    setPhoneNumberCountryCode(isoCode);
    console.log('Phone Number:', value);
    console.log('Country ISO Code:', isoCode);
  };

  useEffect(() => {
    let mounted = true;

    const fetchAccessToken = async () => {
      try {
        const token = await getAirtimeAccessToken();
        console.log(token);
        if (mounted) {
          setAccessToken(token);
        }
      } catch (error) {
        console.error('Failed to fetch airtime access token:', error);
      }
    };

    fetchAccessToken();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchIPData();
      if (data) {
        setIP(data.ip);
        setCountryName(data.country_name);
        setCountryCode(data.country_code);
        // console.log(data);
      }
    };
    getData();
  }, []);

  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    console.log('Send button clicked');

    try {
      // Ensure required data is available
      if (!operatorData.operatorId || !amount || !phoneNumber) {
        throw new Error('Missing required fields');
      }

      const url = 'https://topups.reloadly.com/topups';
      const requestBody = {
        operatorId: operatorData.operatorId, // required
        amount: amount, // required
        useLocalAmount: isLocal, // default false
        customIdentifier: `https://www.joegalaxy.net ${
          player.data.player.playerId
        }${phoneNumber}${Date.now()}`,
        recipientPhone: {
          // required
          countryCode: phoneNumberCountryCode,
          number: phoneNumber,
        },
      };

      console.log('Request Body:', requestBody);

      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          Accept: 'application/com.reloadly.topups-v1+json',
          'Content-Type': 'application/json',
        },
      });

      console.log('Response Data:', response.data);
      setSuccess(
        `Top-up successful! Transaction ID: ${response.data.transactionId}`,
      );
      setLoading(false);
      setVisible(2);

      // Update the player's JGK tokens
      //api call to backend to update player's jgk tokens
    } catch (err) {
      console.error('Error:', err);
      setError('Top-up failed. Please try again.');
    } finally {
      setLoading(false);
      console.log('Loading state set to false');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      if (!accessToken || !accessToken.access_token) {
        throw new Error('Access token is not available');
      }

      const countryIsoCode = phoneNumberCountryCode;
      // console.log('Access Token:', accessToken.access_token);
      // console.log('Country ISO Code:', countryIsoCode);
      // console.log('Phone Number:', phoneNumber);

      const url = `https://topups.reloadly.com/operators/auto-detect/phone/${phoneNumber}/countries/${countryIsoCode}`;
      // console.log('Request URL:', url);

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          Accept: 'application/com.reloadly.topups-v1+json',
        },
      });

      console.log('Response Data:', response.data);
      setOperatorData(response.data);
      //check if player is local to the country to set useLocalAmount to true
      console.log(countryCode, response.data?.country.isoName);
      const isLocal =
        countryCode === response.data?.country.isoName ? true : false;
      if (isLocal && response.data?.supportsLocalAmounts === true) {
        setIsLocal(isLocal);

        //set useLocalAmount to true
        setShowMin(response.data?.minAmount * response.data?.fx.rate);
        setShowMax(response.data?.maxAmount * response.data?.fx.rate);
        setAirtimeTopupFee(response.data?.fees.local);
      } else {
        setShowMin(response.data?.minAmount);
        setShowMax(response.data?.maxAmount);
        setAirtimeTopupFee(response.data?.fees.international);
      }
      setAmount(response.data?.minAmount);
      setJgkAmount(
        response.data?.minAmount * 100 +
          airtimeTopupFee * response.data?.fx.rate * 100,
      );
      console.log(isLocal);
      const fee = isLocal
        ? response.data?.fees.localFee
        : response.data?.fees.international;
      console.log(fee);
      console.log(
        'JGK Amount:',
        response.data?.minAmount * 100 + fee * response.data?.fx.rate * 100,
      );
      setSuccess('Top-up successful!');
      setVisible(1);
    } catch (err) {
      console.error('Error:', err);
      setError('Top-up failed. Please try again.');
    } finally {
      setLoading(false);
      console.log('Loading state set to false');
    }
  };

  const handleChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);

    // Calculate the initial JGK amount
    let newJgkAmount =
      newAmount * 100 + airtimeTopupFee * operatorData.fx.rate * 100;

    // Add an additional 3% fee
    newJgkAmount = newJgkAmount + newJgkAmount * 0.03;

    setJgkAmount(newJgkAmount);
    console.log('Amount:', newAmount);
    console.log(
      'JGK Amount:',
      newJgkAmount,
      'JGK Tokens:',
      player.data.player.jgkTokens,
    );
  };

  return (
    <>
      <Topbar />
      {mobileView && <TopbarNotification />}
      <div className="homeContainer">
        <Sidebar />
        <div className="feed">
          <div className="rld-card__wrapper">
            <section className="cardcontent">
              <div className="menu"></div>
              <div className="main-con">
                <div className="topup-container">
                  <h2>Mobile top-up</h2>
                  <p className="sub-heading">
                    Follow three easy steps to top-up in seconds. To do, please
                    set the receiver phone number below:
                  </p>
                  {visible === 0 && (
                    <form onSubmit={handleSubmit} className="is-12 w-100">
                      <div className="entry">
                        <label htmlFor="phone" className="label">
                          Add phone number
                        </label>
                        <PhoneInput
                          country={'us'}
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                          containerClass="phone-input-container"
                          inputClass="phone-input"
                          enableSearch
                        />
                        <button
                          type="submit"
                          className="loginButton"
                          disabled={loading}
                        >
                          {loading ? 'Processing...' : 'Next'}
                        </button>
                        {error && <div className="error_text">{error}</div>}
                        {success && (
                          <div className="success_text">{success}</div>
                        )}
                      </div>
                    </form>
                  )}
                  {visible === 1 && (
                    <form noValidate className="ng-untouched ng-valid ng-dirty">
                      <div className="phone-input ">
                        <input
                          type="tel"
                          autoComplete="off"
                          id="phone"
                          placeholder={phoneNumber}
                          maxLength="15"
                          validation="true"
                          className="input-container input-large ng-untouched ng-pristine"
                          disabled
                        />
                      </div>
                      <div className="operator-info">
                        <div className="operator-icon">
                          <img
                            className="operator-image"
                            src={operatorData?.logoUrls?.[1]}
                          />
                        </div>
                        <div className="details">
                          <p className="detected-operater ng-star-inserted">
                            {' '}
                            We detected {operatorData?.name}{' '}
                          </p>
                          <p className="not-operator ng-star-inserted">
                            {' '}
                            Not the operator? <span>Check operators</span>
                          </p>
                        </div>
                      </div>
                      <div className="fixed-amounts">
                        <div className="entry">
                          <label htmlFor="alt-ip" className="label">
                            Add amount{' '}
                          </label>
                          <div className="input-box w-100">
                            <input
                              size="large"
                              type="number"
                              id="alt-ip"
                              placeholder={`${showMin}`}
                              formcontrolname="amount"
                              className="input w-100 input-container input-large ng-untouched ng-pristine ng-valid"
                              min={showMin}
                              max={showMax}
                              value={amount}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <p className="note">
                          Amount has to be between ${showMin} and ${showMax}
                        </p>
                      </div>
                      <button
                        // reloadly-button
                        variant="filled"
                        color="secondary"
                        className="chatSubmitButton button-margin"
                        onClick={() => setVisible(0)}
                      >
                        Back
                      </button>
                      <button
                        // reloadly-button
                        variant="filled"
                        color="primary"
                        className={
                          amount >= showMin &&
                          amount <= showMax &&
                          player.data.player.jgkTokens >= jgkAmount
                            ? 'airtimeSubmitButton button-margin'
                            : 'airtimeSubmitButtonDisabled button-margin'
                        }
                        onClick={handleSend}
                        disabled={
                          amount < showMin ||
                          amount > showMax ||
                          loading ||
                          player.data.player.jgkTokens < jgkAmount
                        }
                      >
                        Send
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
};

export default Airtime;
