import './share.css';
import validator from 'validator';
import Form from 'react-bootstrap/Form';
import { useConfettiText } from '../../hooks/useConfettiText';
import ShareJGButton from '../shareJGButton/ShareJGButton';
import { uploadProgress } from '../../utils/uploadProgress';
import isVideoFile from '../../utils/isVideoFile';
import {
  PermMedia,
  Label,
  // Room,
  // EmojiEmotions,
  Cancel,
} from '@material-ui/icons';
import { useEffect, useContext, useRef, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import axiosConn from '../../axiosConn';
// import InputEmoji from 'react-input-emoji';
import { useMediaQuery } from 'react-responsive';
import useMobileView from '../../hooks/useMobileView';
import Picker from 'emoji-picker-react';
import { ClipLoader } from 'react-spinners';
import useClickOutside from '../../clickOutside';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Button from '@mui/material/Button';

import { CircularProgress } from '@material-ui/core';
import LoadingBar from 'react-top-loading-bar';
//import { ProgressBar } from 'react-bootstrap';
import { ViewPortContext } from '../../context/ViewPortContext';
import { SocketContext } from '../../context/socket';
import { MAX_VIDEO_SIZE, MAX_IMAGE_SIZE } from '../../config/constants';

const MAX_VIDEO_SIZE_MB = MAX_VIDEO_SIZE / (1024 * 1024); // Convert bytes to megabytes
const MAX_IMAGE_SIZE_MB = MAX_IMAGE_SIZE / (1024 * 1024); // Convert bytes to megabytes
const SHAREJGBUTTON_PARTICLE_COUNT = 2000;

const MESSAGE_PARTICLE_COUNT = 1000;

export default function Share({
  setNewPost,
  parent = '',
  galaxyId = '',
  isGalaxyMember = false,
  subscriptionStatus = 'Subscribe',
}) {
  // console.log(parent, galaxyId);
  const { player } = useContext(AuthContext);
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const desc = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const [descr, setDescr] = useState('');
  const [namer, setNamer] = useState('');
  const [emailr, setEmailr] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const fileInput = useRef(null);
  const [progress, setProgress] = useState(0);

  const [text, setText] = useState('');
  const [cursorPosition, setCursorPosition] = useState();
  const [loading, setLoading] = useState(false);
  const { width } = useContext(ViewPortContext);
  const [shareJG, setShareJG] = useState(0);
  const [readyToShareJG, SetReadyToShareJG] = useState(false);
  const [picker, setPicker] = useState(false);
  const [pogw, SetPogw] = useState(false);
  const socket2 = useContext(SocketContext);

  const shareRef = useRef(null);
  const menu = useRef(null);

  const handleShareJGToggle = () => {
    shareJG === 0 ? setShareJG(1) : setShareJG(0);
    // console.log(shareJG);
  };
  const [galaxyMember, setGalaxyMember] = useState(isGalaxyMember);
  const [isDisabled, setIsDisabled] = useState(
    // (parent === 'galaxy' && !galaxyMember) ||
    parent === 'galaxy' && subscriptionStatus !== 'Subscribed',
  );

  useEffect(() => {
    setGalaxyMember(isGalaxyMember);
  }, [isGalaxyMember]);

  useEffect(() => {
    // console.log(parent);
    setIsDisabled(
      // (parent === 'galaxy' && !galaxyMember) ||
      parent === 'galaxy' && subscriptionStatus !== 'Subscribed',
    );
  }, [parent, galaxyMember, subscriptionStatus]);

  const handleClickOutside = () => {
    setPicker((prev) => !prev);
  };

  useClickOutside(menu, () => handleClickOutside());

  useEffect(() => {
    if (cursorPosition && desc !== null) {
      desc.current.selectionStart = cursorPosition;
      desc.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);

  const handleEmoji = (e, { emoji }) => {
    const ref = desc.current;
    ref.focus();
    const start = ref.value.substring(0, ref.selectionStart);
    const end = ref.value.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setDescr(
      (prevDescr) =>
        prevDescr.substring(0, ref.selectionStart) +
        emoji +
        prevDescr.substring(ref.selectionStart),
    );
    setCursorPosition(start.length + emoji.length);
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const mobileView = useMobileView();

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    shareRef,
    mobileView,
    '+10 JGK Win!',
    MESSAGE_PARTICLE_COUNT,
    'share',
  );

  const inputRef2 = useRef(null);
  const [showConfettiText2, ConfettiTextComponent2] = useConfettiText(
    inputRef2, // Pass inputRef2 directly, not wrapped in an object
    mobileView,
    '+20 JGK Big Play!',
    SHAREJGBUTTON_PARTICLE_COUNT,
    'shareJGButton',
    false,
  );

  const resetForm = () => {
    setDescr('');
    setNamer('');
    setEmailr('');
    SetReadyToShareJG(false);
    setShareJG(0);
    setIsFetching(false);
  };

  const shareJGHandler = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    try {
      const res = await axiosConn.post(
        '/invites',
        {
          player: player.data.player._id,
          firstName: namer,
          emailAddress: emailr,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      resetForm();
      showConfettiText2();
    } catch (err) {
      resetForm();
      setError(err.message);
      console.log(err);
    }
  };

  const shareJGChecker = (e) => {
    if (validator.isAlpha(namer) && validator.isEmail(emailr)) {
      SetReadyToShareJG(true);
      // console.log('readyToShareJG');
    } else SetReadyToShareJG(false);
  };

  // const MAX_VIDEO_SIZE = 1024 * 1024 * 90;
  // const MAX_IMAGE_SIZE = 1024 * 1024 * 20;
  const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/joegalaxy/';

  const isVideo = (fileName) => isVideoFile(fileName.toLowerCase());
  const isSupportedFileType = (file) =>
    [
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/gif',
      'video/mp4',
      'video/quicktime', // MIME type for .mov files
    ].includes(file.type);
  const isFileSizeValid = (file) => {
    if (isVideo(file.name)) {
      return file.size <= MAX_VIDEO_SIZE;
    } else {
      return file.size <= MAX_IMAGE_SIZE;
    }
  };

  const createGalaxyPost = async (imgUrl) => {
    try {
      const res = await axiosConn.post(
        `/galaxies/${galaxyId}/posts`,
        {
          playerId: player.data.player.playerId,
          desc: descr,
          img: imgUrl,
          isPogw: pogw,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      setNewPost(res.data.post);
      showConfettiText();
      socket2.emit('sendPost', { post: res.data.post });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError('You must be a Galaxy member to create a Galaxy Post');
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const createPost = async (imgUrl) => {
    const res = await axiosConn.post(
      '/posts',
      {
        playerId: player.data.player.playerId,
        desc: descr,
        img: imgUrl,
        isPogw: pogw,
      },
      {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      },
    );
    setNewPost(res.data.data.data);
    showConfettiText();
    socket2.emit('sendPost', { post: res.data.data.data });
  };

  const submitHandler = async (e) => {
    // console.log('submitHandler');
    e.preventDefault();
    setIsFetching(true);
    desc.current.value = descr;

    if (file) {
      // console.log('submitHandler', file.name);
      if (!isSupportedFileType(file)) {
        setError(`${file.name} format is not supported.`);
        return;
      }
      if (!isFileSizeValid(file)) {
        const maxSize = isVideo(file.name)
          ? `${MAX_VIDEO_SIZE_MB}mb`
          : `${MAX_IMAGE_SIZE_MB}mb`;
        setError(`${file.name} is too large max ${maxSize} allowed.`);
        return;
      }

      let data = new FormData();
      const fileName = Date.now() + file.name;
      data.append('name', fileName);
      data.append('file', file);
      data.append('upload_preset', 'cubejg');
      data.append('cloud_name', 'joegalaxy');

      const config = uploadProgress(setProgress, setUploadPercentage);

      try {
        let url =
          CLOUDINARY_URL +
          (isVideo(fileName) ? 'video/upload' : 'image/upload');
        await axios.post(url, data, config).then(async (data) => {
          const SecurePostImgQ_AutoUrl =
            data.data.secure_url.split('/upload/')[0] +
            '/upload/q_auto/fl_progressive:steep/' +
            data.data.secure_url.split('/upload/')[1];
          if (parent === 'galaxy') {
            // console.log(parent);
            await createGalaxyPost(SecurePostImgQ_AutoUrl);
          } else {
            // console.log(parent);

            await createPost(SecurePostImgQ_AutoUrl);
          }
        });
        setDescr('');
        setFile(null);
        setIsFetching(false);
      } catch (err) {
        setError(err.message);
        setIsFetching(false);
      }
    } else if (descr !== '') {
      try {
        if (parent === 'galaxy') {
          // console.log(parent);
          await createGalaxyPost(null);
        } else {
          await createPost(null);
        }
        setDescr('');
        setIsFetching(false);
        setFile(null);
      } catch (err) {
        // console.log(err);
        setError(err.message);
        setIsFetching(false);
      }
    } else {
      setIsFetching(false);
    }
    // console.log('submitHandler end');
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (!file) {
      // No file selected or input cleared
      return;
    }
    // console.log('handleFileInput', file.name);
    const supportedTypes = [
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/gif',
      'video/mp4',
      'video/quicktime', // MIME type for .mov files
    ];
    const maxSize = MAX_VIDEO_SIZE; // 90mb

    if (!supportedTypes.includes(file.type)) {
      setError(`${file.name} format is not supported.`);
    } else if (file.size > maxSize) {
      setError(
        `${file.name} is too large, max ${MAX_VIDEO_SIZE_MB}mb allowed.`,
      );
    } else {
      setFile(file);
    }
  };

  const handleProofOfGreatWorkCheck = () => {
    // console.log('handleProofOfGreatWorkCheck()');
    SetPogw((prev) => !prev);
  };

  // console.log(galaxyMember === false);
  // console.log(subscriptionStatus, 'isDisabled', isDisabled);

  return (
    <div className="share">
      <LoadingBar
        color="limegreen"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="shareWrapper">
        <div className={!mobileView ? 'shareTop' : 'shareTopMobile'}>
          <img
            className={
              !mobileView ? 'shareProfileImg' : 'shareProfileImgMobile'
            }
            src={
              player.data.player.profilePhoto
                ? player.data.player.profilePhoto
                : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
            }
            alt=""
          />
          {/* <InputEmoji
            placeholder={
              "What's on your mind " + player.data.player.playerId + '?'
            }
            className="shareInput"
            ref={desc}
            onChange={setDescr}
            fontSize={!mobileView ? 'small' : 'x-small'}
          /> */}
          {shareJG === 1 ? (
            <>
              <div className="shareJG">
                <div className="shareJGName">
                  <div
                    className={
                      !mobileView
                        ? 'share_jg_comment_input_wrap'
                        : 'shareJG_wrapMobile'
                    }
                  >
                    <input
                      required
                      className="loginInput"
                      type="text"
                      ref={nameRef}
                      value={namer}
                      placeholder={'First Name'}
                      onInput={(e) => {
                        setNamer(e.target.value);
                        shareJGChecker();
                      }}
                      onKeyUp={(e) => {
                        if (
                          e.key === 'Backspace' ||
                          (e.ctrlKey && e.key === 'z')
                        ) {
                          shareJGChecker();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="shareJGName">
                  <div
                    className={
                      !mobileView
                        ? 'share_jg_comment_input_wrap'
                        : 'shareJG_wrapMobile'
                    }
                  >
                    <input
                      type="email"
                      required
                      className="loginInput"
                      ref={emailRef}
                      value={emailr}
                      placeholder={'Email Address'}
                      onInput={(e) => {
                        setEmailr(e.target.value);
                        shareJGChecker();
                      }}
                      onKeyUp={(e) => {
                        if (
                          e.key === 'Backspace' ||
                          (e.ctrlKey && e.key === 'z')
                        ) {
                          shareJGChecker();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className={
                !mobileView ? 'comment_input_wrap' : 'shareMessage_wrapMobile'
              }
            >
              {picker && (
                <div className="comment_emoji_picker" ref={menu}>
                  <Picker onEmojiClick={handleEmoji} />
                </div>
              )}
              <input
                key={isDisabled}
                disabled={isDisabled}
                type="text"
                ref={desc}
                value={descr}
                placeholder={
                  "What's on your mind " + player.data.player.playerId + '?'
                }
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length > 4096) {
                    setDescr(inputValue.substring(0, 4096));
                  } else {
                    setDescr(inputValue);
                  }
                }}
                onInput={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length > 4096) {
                    setDescr(inputValue.substring(0, 4096));
                  }
                }}
                onKeyUp={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length > 4096) {
                    setDescr(inputValue.substring(0, 4096));
                  } else {
                    setDescr(inputValue);
                  }
                }}
              />
              {!mobileView && (
                <p
                  style={{ color: descr.length <= 4096 ? 'limegreen' : 'red' }}
                >
                  {4096 - descr.length}
                </p>
              )}
              <div className="comment_circle" style={{ marginTop: '5px' }}>
                <ClipLoader
                  size={10}
                  color="#1876f2"
                  // loading={loading}
                  loading={isFetching}
                />
              </div>
              {/* <div
                className="comment_circle_icon hover2"
                onClick={submitHandler}
              >
                <i
                  className={!mobileView ? 'share_icon' : 'share_iconMobile'}
                ></i>
              </div> */}
              {
                <div
                  className="comment_circle_icon hover2"
                  onClick={() => {
                    setPicker((prev) => !prev);
                  }}
                >
                  <i className="emoji_icon"></i>
                </div>
              }
              <div
                className="comment_circle_icon hover2"
                onClick={() => fileInput.current.click()}
              >
                <i className="addPhoto_icon"></i>
              </div>
            </div>
          )}
        </div>
        <div>
          {shareJG === 1 && (
            <span className="shareJGText">
              You Receive 20 JGK Credits & 100 on Conversion!
            </span>
          )}
        </div>
        <hr className="shareHr" ref={shareRef} />
        <ConfettiTextComponent />
        {error && (
          <div className="shareError_error">
            <div className="error_text">{error}</div>
            <button
              className="blue_btn"
              onClick={() => {
                setDescr('');

                setError('');
                setFile(null);
                fileInput.current.value = null;

                setIsFetching(false);
              }}
            >
              Try again
            </button>
          </div>
        )}
        {file &&
          (isVideoFile(file.name.toLowerCase()) ? (
            <div className="shareVidContainer">
              <video className="postVid" controls>
                <source src={URL.createObjectURL(file)} type="video/mp4" />
              </video>
              <Cancel
                disabled={isFetching}
                className="shareCancelVid"
                onClick={() => {
                  setFile(null);
                  setIsFetching(false);
                  fileInput.current.value = null;
                }}
              />
            </div>
          ) : (
            <div className="shareImgContainer">
              <img
                className="shareImg"
                src={URL.createObjectURL(file)}
                alt=""
              />
              <Cancel
                disabled={isFetching}
                className="shareCancelImg"
                onClick={() => {
                  setFile(null);
                  setIsFetching(false);
                  fileInput.current.value = null;
                }}
              />
            </div>
          ))}
        <form
          className="shareBottom"
          onSubmit={shareJG === 1 ? shareJGHandler : submitHandler}
        >
          <div className="shareOptions">
            <label htmlFor="file" className="shareOption">
              <PermMedia
                htmlColor={shareJG === 1 || isDisabled ? 'grey' : 'tomato'}
                className={!mobileView ? 'shareIcon' : 'shareIconMobile'}
                style={
                  width > 519 ? { fontSize: '35px' } : { fontSize: '25px' }
                }
              />
              <span
                className={
                  !mobileView ? 'shareOptionText' : 'shareOptionTextMobile'
                }
              >
                {/* Photo or Video */}
              </span>

              <input
                ref={fileInput}
                type="file"
                style={{ display: 'none' }}
                id="file"
                accept=".png,.jpeg,.jpg,.mp4,.mov"
                onChange={(e) => {
                  handleFileInput(e);
                }}
                disabled={shareJG === 1 || isDisabled}
              />
            </label>
            <ConfettiTextComponent2 />
            <div ref={inputRef2}>
              <ShareJGButton
                pogw={pogw}
                handleShareJGToggle={handleShareJGToggle}
                shareJG={shareJG}
                mobileView={mobileView}
                width={width}
                color="#9c27b0"
              />
            </div>
            {/*
            <div className="shareOption">
              <Room htmlColor="green" className="shareIcon" />
              <span className="shareOptionText">Location</span>
            </div>
            
            <div className="shareOption">
              <EmojiEmotions htmlColor="goldenrod" className="shareIcon" />
              <span className="shareOptionText">Feelings</span>
            </div>
            */}
            <div className="pogwDiv shareOption">
              <Form className="pogwCheckBox">
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={
                        <span
                          style={{ color: shareJG === 1 ? 'black' : 'inherit' }}
                        >
                          Proof Of Great Work?
                        </span>
                      }
                      defaultChecked={pogw}
                      disabled={shareJG === 1}
                      onClick={handleProofOfGreatWorkCheck}
                    />
                  </div>
                ))}
              </Form>
            </div>
            {width > 280 && !pogw && (
              <div className="tooltipPOGW">
                <InfoOutlinedIcon
                  className="sidebarIconTooltip"
                  fontSize="small"
                  color={shareJG === 1 ? 'black' : 'secondary'}
                />
                <span className="tooltiptextPOGW">
                  <p>- Proof of Great Work are the "receipts".</p>
                  <p>
                    - POGW is any evidence of great work on your part that
                    creates value & Klout in your life! Check the box when
                    posting a Proof Of Great Work to enable your POGW Post to
                    receive JGK.
                  </p>
                </span>
              </div>
            )}
          </div>
          <button
            className={
              !mobileView
                ? readyToShareJG || shareJG === 0
                  ? 'shareButton'
                  : 'shareButtonDisabled'
                : readyToShareJG || shareJG === 0
                ? 'shareButtonMobile'
                : 'shareButtonMobileDisabled'
            }
            type="submit"
            disabled={
              isFetching ||
              (!readyToShareJG && shareJG === 1) ||
              (parent === 'galaxy' && galaxyMember === false)
            }
          >
            {isFetching ? (
              <CircularProgress color="inherit" size="20px" />
            ) : (
              'Share'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
