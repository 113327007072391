import { useContext, useRef, useState, useEffect } from 'react';
import './login.css';
import Footer from '../../components/footer/Footer';
import { useConfettiText } from '../../hooks/useConfettiText';
import { loginCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import SpotifyIframeNormalBlack from '../../utils/spotifyIframe';
import { ViewPortContext } from '../../context/ViewPortContext';
import { useLocation } from 'react-router-dom';

const LOGIN_PARTICLE_COUNT = 100;

export default function Login() {
  const [loginSuccess, setLoginSuccess] = useState(false);

  const emailAddress = useRef();
  const password = useRef();
  const { isFetching, dispatch } = useContext(AuthContext);
  const { height } = useContext(ViewPortContext);
  // console.log(height);
  //const { error } = useContext(AuthContext);
  const [error, setError] = useState('');
  const inputRef = useRef(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoTagline, setLogoTagline] = useState('');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let from = query.get('from');
  const fromRef = useRef(null);

  // console.log('location.pathname: ', location.pathname);
  const desktopView = useMediaQuery({
    query: '(min-width: 805px)',
  });
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    inputRef,
    mobileView,
    '+2 JGK Welcome!',
    LOGIN_PARTICLE_COUNT, // Particle count for the confetti
    'login', // Parent component name
    false, // isVoiceMemo
  );

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //console.log(desktopView);
  const handleClick = async (e) => {
    e.preventDefault();

    //console.log('clicked');
    //console.log(emailAddress.current.value, password.current.value);
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   console.log(position.coords.latitude, position.coords.longitude);
    // });
    const result = await loginCall(
      {
        emailAddress: emailAddress.current.value,
        password: password.current.value,
      },
      dispatch,
    );

    if (isMounted.current) {
      if (result === 'success') {
        // console.log(result);
        setLoginSuccess(true);
      } else {
        setError(result.response.statusText);
      }
    }
  };

  useEffect(() => {
    if (loginSuccess) {
      // console.log('loginSuccess');
      showConfettiText();
    }
  }, [loginSuccess]);

  useEffect(() => {
    console.log('from', from);
    localStorage.setItem('from', from);

    // Check if unlockedGalaxies is true
    const unlockedGalaxies =
      localStorage.getItem('unlockedGalaxies') === 'true';
    if (!unlockedGalaxies) {
      const originFrom = localStorage.getItem('originFrom');
      if (!originFrom || originFrom === 'null') {
        console.log(
          'there is no originFrom, or it is null, setting now to: ',
          from,
        );
        localStorage.setItem('originFrom', from);
      } else {
        console.log('originFrom already set to: ', originFrom);
      }
    } else {
      console.log('unlockedGalaxies is true, not setting originFrom');
    }

    if (!from || from === 'null') {
      console.log('from is null');
      from = localStorage.getItem('originFrom');
      localStorage.setItem('from', from);
    }
    // Assign the value to the 'fromRef.current'
    fromRef.current = from;
  }, [from]);

  useEffect(() => {
    if (
      from?.includes('/galaxies/camden-live-galaxy') ||
      from?.includes('/galaxies/65e95c2b95ff6915e2f5f361')
    ) {
      // console.log('from galaxies/65e95c2b95ff6915e2f5f361');
      setLogoUrl(
        'https://res.cloudinary.com/joegalaxy/image/upload/v1715229523/thielxhez0teywzmwycg_srujsi.jpg',
      );
      setLogoTagline('Come on down, come on down!');
    } else if (
      from?.includes('/galaxies/la-joya-eco-community') ||
      from?.includes('667de222b7513bdf52193144')
    ) {
      setLogoUrl(
        'https://res.cloudinary.com/joegalaxy/image/upload/v1719531368/LaJoyaLogo_cbapwt.jpg',
      );
      setLogoTagline('Adventure, Philanthropy & Prosperity are a Way of Life.');
    } else if (from?.includes('/galaxies/660a1c8149a3f75c60b457e1')) {
      setLogoUrl(
        'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1721518015/Images/tomq4dq5uiglbr2tcgil.png',
      );
      setLogoTagline('JGSoccer.Com Galaxy');
    } else if (
      from?.includes('/galaxies/66ef5e0d1db6c7febd8a5194') ||
      from?.includes('/galaxies/kebell-football')
    ) {
      setLogoUrl(
        'https://res.cloudinary.com/joegalaxy/image/upload/v1726985209/WhatsApp_Image_2024-09-22_at_00.57.28_f8e36b89_x9ufdf.jpg',
      );
      setLogoTagline('Kebell Football Galaxy');
    } else {
      setLogoUrl(
        'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png',
      );
      setLogoTagline('This game is your Life! See you at the Top...');
    }
  }, []);

  // console.log(from);
  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginLeft">
          <div>
            <img src={logoUrl} alt="Joe Galaxy Logo"></img>
          </div>
          {/* <h3 className="loginLogo">JOE GALAXY</h3> */}
          <span className="loginDesc">
            <p>
              {logoTagline.split(/\.\s|!\s/)[0] +
                (logoTagline.includes('.')
                  ? '.'
                  : logoTagline.includes('!')
                  ? '!'
                  : '')}
            </p>
            <p className="tag">
              {logoTagline
                .split(/\.\s|!\s/)
                .slice(1)
                .join('. ')}
            </p>
          </span>
          {/* <SpotifyIframeNormalBlack /> */}
        </div>
        <div className="loginRight">
          <div className="logoDescMobile">
            <div>
              <img src={logoUrl} alt="Joe Galaxy Logo"></img>
            </div>
            {/* <h3 className="loginLogo">JOE GALAXY</h3> */}
            <span className="loginDesc">
              <p>
                {logoTagline.split(/\.\s|!\s/)[0] +
                  (logoTagline.includes('.')
                    ? '.'
                    : logoTagline.includes('!')
                    ? '!'
                    : '')}
              </p>
              <p className="tag">
                {logoTagline
                  .split(/\.\s|!\s/)
                  .slice(1)
                  .join('. ')}
              </p>
            </span>
          </div>
          <form className="loginBox" onSubmit={handleClick}>
            <input
              placeholder="Email"
              type="email"
              required
              className="loginInput"
              ref={emailAddress}
              autoComplete="email"
            />
            <input
              placeholder="Password"
              type="password"
              required
              minLength="8"
              className="loginInput"
              ref={password}
              autoComplete="current-password"
            />
            {loginSuccess && <ConfettiTextComponent />}

            {error && <div className="error_text">{error}</div>}
            <button
              ref={inputRef}
              className="loginButton"
              type="submit"
              disabled={isFetching}
            >
              {isFetching ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                'Log In'
              )}
            </button>
            <Link
              to="/reset"
              className="forgotPassword"
              style={{ textDecoration: 'none' }}
              title="Joe Galaxy Password Recovery"
            >
              <span className="loginForgot">Forgot Password?</span>
            </Link>
            <div className="loginCenter">
              <Link
                to="/register/1"
                style={{ textDecoration: 'none' }}
                title="Create a New Joe Galaxy Player Account"
              >
                <button className="loginRegisterButton" disabled={isFetching}>
                  Create a New Account
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      {!mobileView && height >= 563 && <Footer />}
    </div>
  );
}
