import './sidebar.css';
import //  RssFeed,
//Chat,
//PlayCircleFilledOutlined,
// Group,
// Bookmark,
// HelpOutline,
// WorkOutline,
// Event,
// School,
'@material-ui/icons';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';

import EasyEdit from 'react-easy-edit';

import { Link } from 'react-router-dom';
//import CloseFriend from '../closeFriend/CloseFriend';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PersonPinIcon from '@mui/icons-material/PersonPin';
// import Channel from '../channel/Channel';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'; // Import Feedback icon
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import DisplayPlayerJGK from '../displayPlayerJGK/DisplayPlayerJGK';
import { ViewPortContext } from '../../context/ViewPortContext';
import isExcluded from '../../utils/isExcluded';

import JGKLeaderBoard from '../jgkLeaderBoard/JGKLeaderBoard';
// import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
// import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';

export default function Sidebar() {
  const [isLoading, setIsLoading] = useState(false);
  const [channelItems, setChannelItems] = useState([]);
  const [galaxyChannelItems, setGalaxyChannelItems] = useState([]);
  const [galaxyChannelCount, setGalaxyChannelCount] = useState(7);
  const [allPlayersJGK, setAllPlayersJGK] = useState([]);
  const [allPlayerCount, setAllPlayerCount] = useState(6);

  const { width } = useContext(ViewPortContext);
  const { player } = useContext(AuthContext);
  // const [editMode, setEditMode] = useState(false);
  const [rerender, setRerender] = useState(false);

  const [defaultValue, setDefaultValue] = useState(
    'Enter Your Channel Link Url.',
  );
  //  const from = localStorage.getItem('from');
  // console.log(from);
  // const excludedIds = ['660a1c8149a3f75c60b457e1'];
  // const isExcluded = excludedIds.some((id) => from.includes(id));

  const handleSave = async (text) => {
    // console.log(text);
    if (text === null || text.length < 10 || !text.includes('http://')) {
      alert('Please enter a valid url: http://YourDomain.com');
      // setRerender(!rerender);
      // setDefaultValue(defaultValue);
      return;
    } else {
      //  console.log(text);
    }
    try {
      await axiosConn.post(
        `/channels/`,
        { url: text, playerId: player.data.player.playerId },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      setRerender(!rerender);
      setDefaultValue(defaultValue);
      // window.location.reload();
    } catch (err) {}
  };

  const handleCancel = (text) => {
    //console.log(text);
  };

  // useEffect(() => {
  //   let mounted = true;
  //   const getGalaxyChannels = async () => {
  //     try {
  //       setIsLoading(true);
  //       // console.log(player);
  //       const res = await axiosConn.get(`/channels/`, {
  //         headers: {
  //           Authorization: `Bearer ${player.token}`,
  //         },
  //       });
  //       //console.log('galaxyChannelItems: ', res);
  //       if (!mounted) return;
  //       setGalaxyChannelItems(res.data.data.data);
  //       setIsLoading(false);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getGalaxyChannels();
  //   return () => {
  //     mounted = false;
  //   };
  // }, [galaxyChannelItems.length, player.token, player.data.player.playerId]);

  // useEffect(() => {
  //   let mounted = true;
  //   const getChannels = async () => {
  //     try {
  //       setIsLoading(true);
  //       // console.log(player);
  //       const res = await axiosConn.get(
  //         `/channels/profile/${player.data.player.playerId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${player.token}`,
  //           },
  //         },
  //       );
  //       if (!mounted) return;
  //       // console.log('channelItems: ', res.data);
  //       setChannelItems(res.data);
  //       setIsLoading(false);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getChannels();
  //   return () => {
  //     mounted = false;
  //   };
  // }, [
  //   rerender,
  //   defaultValue,
  //   channelItems.length,
  //   player.token,
  //   player.data.player.playerId,
  // ]);

  useEffect(() => {
    let mounted = true;
    const getAllPlayersSortJGK = async () => {
      try {
        setIsLoading(true);
        // console.log(player);
        const res = await axiosConn.get(`/players/?sort=-jgkTokens`, {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        });
        if (!mounted) return;
        // console.log('players JGK Sorted: ', res.data.data.data);
        setAllPlayersJGK(res.data.data.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getAllPlayersSortJGK();
    return () => {
      mounted = false;
    };
  }, [
    rerender,
    defaultValue,
    channelItems.length,
    player.token,
    player.data.player.playerId,
  ]);

  const showMore = () => {
    setGalaxyChannelCount((prev) => prev + 1);
  };
  const showMoreP = () => {
    setAllPlayerCount((prev) => prev + 1);
  };
  // console.log(isExcluded('Galaxies'));
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <ul className="sidebarList">
          {player.data.player.jgkTokens >= 4500 && !isExcluded('Galaxies') && (
            <li>
              <Link
                to={`/galaxies/`}
                style={{ textDecoration: 'none' }}
                className="sidebarListItem"
              >
                <Diversity3OutlinedIcon className="sidebarIcon" />
                <span className="sidebarListItemText">
                  Galaxies (<span style={{ color: 'red' }}>Alpha</span>)
                </span>
              </Link>
            </li>
          )}
          <li>
            <Link
              to={`/jgktoken/`}
              style={{ textDecoration: 'none' }}
              className="sidebarListItem"
            >
              <CurrencyExchangeOutlinedIcon className="sidebarIcon" />
              <span className="sidebarListItemText">JGK$ & Gift Cards 🎁</span>
            </Link>
          </li>
          <li>
            <Link
              to={`/messenger/''`}
              style={{ textDecoration: 'none' }}
              className="sidebarListItem"
            >
              <ChatOutlinedIcon className="sidebarIcon" />
              <span className="sidebarListItemText">Chats</span>
            </Link>
          </li>
          {!isExcluded('PlayerMap') && (
            <li>
              <Link
                to="/playermap"
                style={{ textDecoration: 'none' }}
                className="sidebarListItem"
              >
                <PersonPinIcon className="sidebarIcon" />
                <span className="sidebarListItemText">PlayerMap</span>
              </Link>
            </li>
          )}
          {!isExcluded('Ads') && (
            <li>
              <Link
                to="/advertise"
                style={{ textDecoration: 'none' }}
                className="sidebarListItem"
              >
                <SellOutlinedIcon className="sidebarIcon" />
                <span className="sidebarListItemText">Advertise</span>
              </Link>
            </li>
          )}
          {!isExcluded('Memes') && (
            <>
              <li>
                <Link
                  to="/meme"
                  style={{ textDecoration: 'none' }}
                  className="sidebarListItem"
                >
                  <QueuePlayNextOutlinedIcon className="sidebarIcon" />
                  <span className="sidebarListItemText">Meme Machine</span>
                </Link>
              </li>
            </>
          )}
          {/* {!isExcluded('JG Merch') && (
            <li className="sidebarListItem">
              <a
                href="https://shop.spotify.com/en/artist/5BTaTiYdkru9d4BXvi22U7/product/joe-galaxy-logo-unisex-hoodie?utm_source=spotify&utm_medium=app-artistpick&utm_content=direct&utm_term=00a46659d1de99da00d485e8e6820028b92902f04186f63c90176b"
                target="_blank"
                rel="noreferrer"
                title="Joe Galaxy Merchandise"
                style={{ textDecoration: 'none' }}
                className="sidebarListItem"
              >
                <StorefrontIcon className="sidebarIcon" />
                <span className="sidebarListItemText">Merch by Joe Galaxy</span>
              </a>
            </li>
          )} */}
          {!isExcluded('Feedback') && (
            <>
              <li>
                <Link
                  to="/feedback"
                  style={{ textDecoration: 'none' }}
                  className="sidebarListItem"
                >
                  <FeedbackOutlinedIcon className="sidebarIcon" />
                  <span className="sidebarListItemText">Give Feedback</span>
                </Link>
              </li>
            </>
          )}

          {!isExcluded('Free JGEmail') && (
            <>
              <li>
                <a
                  href={`https://webmail.joegalaxy.net/login/?user=${player.data.player.playerId.toLowerCase()}@joegalaxy.net`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                  className="sidebarListItem"
                  title="Hint: Your Password is the same as your JG Password."
                >
                  <EmailOutlinedIcon className="sidebarIcon" />
                  <span
                    className="sidebarListItemText"
                    style={{ textAlign: 'left' }}
                  >
                    <span style={{ display: 'block' }}>Free JGEmail:</span>
                    <span style={{ display: 'block' }}>
                      {player.data.player.playerId.toLowerCase()}
                    </span>
                    <span style={{ display: 'block' }}>@joegalaxy.net</span>
                  </span>
                </a>
              </li>
            </>
          )}
          {/*
          <li className="sidebarListItem">
            <Group className="sidebarIcon" />
            <span className="sidebarListItemText">Groups</span>
          </li>
          <li className="sidebarListItem">
            <Bookmark className="sidebarIcon" />
            <span className="sidebarListItemText">Bookmarks</span>
          </li>
          <li className="sidebarListItem">
            <HelpOutline className="sidebarIcon" />
            <span className="sidebarListItemText">Questions</span>
          </li>
          <li className="sidebarListItem">
            <WorkOutline className="sidebarIcon" />
            <span className="sidebarListItemText">Jobs</span>
          </li>
          <li className="sidebarListItem">
            <Event className="sidebarIcon" />
            <span className="sidebarListItemText">Events</span>
          </li>
          <li className="sidebarListItem">
            <School className="sidebarIcon" />
            <span className="sidebarListItemText">Courses</span>
          </li>
          */}
        </ul>
        <hr className="sidebarHr" />
        {/* <JGKLeaderBoard /> */}
        <h4 className="rightbarTitle">
          {width >= 1158 ? 'JGK$ Top 10 Leader Board' : 'JGK$ Top 10 Leaders'}
          {width >= 1258 && (
            <div className="tooltip">
              <InfoOutlinedIcon
                className="sidebarIconTooltip"
                fontSize="small"
              />
              <span className="tooltiptext">
                <p>You are a "Player" in the Joe Galaxy Game: </p>
                <p>- Your Joe Galaxy Klout is the Score.</p>
                <p>- You are reward JGK for everything you do!</p>
                <p>- Your JGK can also be cashed out for Gift Cards.</p>
                <br></br>
                <p>Stay Tuned for JG Teams or "Cubes".</p>
                <br></br>
              </span>
            </div>
          )}
        </h4>
        <ul className="sidebarChannelList">
          {!isLoading &&
            allPlayersJGK
              .filter((player) => {
                return (
                  player.playerId !== 'secondDayOut' &&
                  player.playerId !== 'infoJG' &&
                  player.playerId !== 'newrockstar' &&
                  player.playerId !== 'stester' &&
                  player.playerId !== 'rockstarJG' &&
                  player.playerId !== 'thirdDayDayOut' &&
                  player.playerId !== 'firstDayOut' &&
                  player.playerId !== 'jgktokenKing' &&
                  player.playerId !== 'btester' &&
                  player.playerId !== 'jgtesterone' &&
                  player.playerId !== 'winninghands' &&
                  player.playerId !== 'InterfaceOne' &&
                  player.playerId !== 'coachCarlosThree' &&
                  player.playerId !== 'FredSanFord' &&
                  player.playerId !== 'supportNET' &&
                  player.playerId !== 'jgdotnet'
                );
              })
              .slice(0, allPlayerCount)
              .map((p) => (
                <DisplayPlayerJGK
                  key={p._id}
                  passedInPlayerId={p.playerId}
                  parent="sidebar"
                />
              ))}
          {allPlayerCount < 10 && (
            <div className="viewMemes" onClick={() => showMoreP()}>
              View {10 - allPlayerCount} more Players
            </div>
          )}
        </ul>
        {/* <ul className="sidebarChannelList">
          {!isLoading &&
            galaxyChannelItems
              .sort((a, b) => {
                return (
                  b.likes.length +
                  b.clicks.length -
                  (a.likes.length + a.clicks.length)
                );
              })
              .slice(0, galaxyChannelCount)

              .map((c) => <Channel key={c._id} channel={c} from="galaxy" />)}
          {galaxyChannelCount < galaxyChannelItems.length && (
            <div className="viewMemes" onClick={() => showMore()}>
              View {galaxyChannelItems.length - galaxyChannelCount} more Galaxy
              Channels
            </div>
          )}
        </ul> */}
        {/*
        <button className="sidebarButton">Show More</button>
        */}
        {/* <hr className="sidebarHr" />
        <h4 className="rightbarTitle">
          {player.data.player.playerId} Channels
        </h4>

        <ul className="sidebarChannelList">
          {channelItems.map((c) => (
            <Channel key={c._id} channel={c} from="player" />
          ))}
        </ul>
        {!isLoading && channelItems.length < 5 && (
          <div className="sidebarInfoContainer">
            <EasyEdit
              type="text"
              placeholder="Enter Your Channel Link Url."
              onSave={handleSave}
              defaultValue={defaultValue}
              saveOnBlur
              attributes={{ name: 'awesome-input', id: 1 }}
              className="rightbarInfoValue"
              deleteButtonLabel="Remove Me!"
              hideDeleteButton={false}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}
