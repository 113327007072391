import './ad.css';
import { useState, useEffect, useContext, useRef, memo } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMediaQuery } from 'react-responsive';
import Like from '../like/Like';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import arePropsEqual from '../../arePropsEqual';
import ReactPlayer from 'react-player';

export default memo(function Ad({ ad }) {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const { player } = useContext(AuthContext);
  const [like, setLike] = useState(ad.likes.length);
  const [isLiked, setIsLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [views, setViews] = useState(ad.views);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const imgRef = useRef(null);

  useEffect(() => {
    setIsLiked(ad.likes.includes(player.data.player.playerId));
  }, [ad.likes, player.data.player.playerId]);

  useEffect(() => {
    let isMounted = true;
    const updateViews = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axiosConn.put(
          '/ads/' + ad._id + '/view',
          {},
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        if (!isMounted) return;
        setViews(response.data.views);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    updateViews();
    return () => {
      isMounted = false;
    };
  }, [ad._id, player.token]);

  const likeHandler = async () => {
    try {
      await axiosConn.put(
        '/ads/' + ad._id + '/like',
        {
          playerId: player.data.player.playerId,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
    } catch (err) {}
    setLike(isLiked ? like - 1 : like + 1);
    setIsLiked(!isLiked);
  };

  const handleAdClick = (ad) => async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      await axiosConn.put(
        '/ads/' + ad._id + '/click',
        {
          playerId: player.data.player.playerId,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      window.open(ad.url, '_blank');
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <>
      <div className="adContainerElement2" id="adContainerElement">
        {isLoading ? (
          <div className="loadingContainer">
            <CircularProgress color="inherit" size="10px" />
          </div>
        ) : (
          <>
            <a
              href={`${ad.url}`}
              target="_blank"
              rel="noreferrer"
              title={`${ad.title} ${ad.clicks.length} click(s)`}
              style={{ textDecoration: 'none' }}
              onClick={handleAdClick(ad)}
            >
              {ReactPlayer.canPlay(ad.img) ? (
                <div className="adOverlay videoAd" onClick={handleAdClick(ad)}>
                  <ReactPlayer
                    url={ad.img}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                    loop
                    muted
                  />
                </div>
              ) : (
                <img
                  className="ad-img2"
                  src={`${ad.img}`}
                  alt=""
                  ref={imgRef}
                />
              )}
            </a>
            <div className="adByNameContainer">
              <Link className="profileLink" to={`/profile/${ad.playerId}`}>
                <span className="adByName">Ad Sponsored by {ad.playerId}</span>
              </Link>

              <div className={!mobileView ? 'adViews' : 'adViewsMobile'}>
                <VisibilityIcon sx={{ color: 'grey', fontSize: 15 }} />
                {isNaN(views) ? 0 : views}
              </div>
            </div>
            <div className="adBottom">
              <Like
                imgRef={imgRef}
                text="+2 JGK, Yay!"
                likes={ad.likes.length}
                likesProfileImgs={[]}
                isLiked={isLiked}
                likeHandler={likeHandler}
                mobileView={mobileView}
                PF={PF}
                textOffset={-15}
              />
              <div className="adBottomRight"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}, arePropsEqual);
